@charset "utf-8";

body {
  font-family: 'Eventsia', sans-serif;
  letter-spacing: -0.02em !important;
  font-size: 15px;
  color: #222;
}

span {
  font-family: 'Eventsia', sans-serif;
}

p {
  margin-top: 0;
  margin-bottom: 5px;
}

div {
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
}

section {
  display: block;
  position: relative;
  box-sizing: border-box;
}

.h1,
.h2,
.h3,
.h4,
.h5,
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Eventsia', sans-serif;
  font-weight: 600;
  line-height: 1.4;
  color: #222;
}

h2 {
  font-size:  26px;
}

.btn {
  padding: 6px 28px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 30px !important;
}

.services .icon {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -ms-border-radius: 50%;
}

.services h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
}


textarea.form-control {
  height: inherit!important;
}

#map {
  width: 100%;
  height: 525px;
}

#map div {
  position: initial;
}

.main-content {
  min-height: 68vh;
}

.announcement_block {
  margin-bottom: 10px;
}

.nav li a.active {
  background: -webkit-gradient(linear, left top, right top, from(rgb(230, 30, 77)), color-stop(50%, rgb(227, 28, 95)), to(rgb(215, 4, 102))) !important;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%) !important;
  border: none !important;
  color:  white;
}

.tab-content {
  padding: 35Px 0px;
}

.swiper-slide:first-child {
  margin-left: 15px;
}

.blog-entry {
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  -webkit-box-shadow: rgb(96 121 159 / 12%) 0px 7px 14px 0px, rgb(0 33 60 / 12%) 0px 3px 6px 0px; 
  box-shadow: rgb(96 121 159 / 12%) 0px 7px 14px 0px, rgb(0 33 60 / 12%) 0px 3px 6px 0px;
  margin-bottom: 15px;
}

.filter-search {
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.5);
  margin-bottom: 15px;
}

.blog-entry .text {
  position: relative;
  width: 100% !important;    
  padding: 15px 20px 10px 20px;
}

.blog-entry .text .meta {
  margin-bottom: 15px;
}

@media(min-width:768px) {
  .blog-entry .text {
    line-height: 1rem;
    background: #fff;
  }
}

.blog-entry .text .heading {
  font-size: 16px;
  font-weight: 600;
  color: #484848;
}

.blog-entry .text .time-loc {
  font-size: 14px;
  font-weight: 400;
  margin: 3px;
}

.blog-entry .text .time-loc span {
  color: #484848;
}

.blog-entry .meta > div {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 15px;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.blog-entry .meta > div a {
  font-size: 15px;
  color: gray;
}

.blog-entry .meta > div a:hover {
  color: #666;
}


body,
html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

img {
  max-width: 100%;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: 0;
}

a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-warp {
  margin: 0 auto;
  padding: 0 15px;
}

.footer-widget .fw-title {
  margin-bottom: 12px;
  color: #222;
}

.footer-widget p {
  margin-bottom: 0;
  font-size: 16px;
  color: #222;
}

.footer-widget.list-widget ul {
  display: inline-block;
  list-style: none;
}

.footer-widget.list-widget ul:last-child {
  margin-right: 0;
}

.footer-widget.list-widget ul li a {
  display: block;
  margin-bottom: 8px;
  color: #222;
}

.filter-p2 ::placeholder {
  color: #222;
  font-style: initial !important;
  font-weight: 600;
}

::placeholder {
  font-style: italic;
  color: #bbb;
  font-weight: 500;
}

::-webkit-input-placeholder {
  font-style: italic;
  color: #bbb;
  font-weight: 500;
}

:-ms-input-placeholder {
  font-style: italic;
  color: #bbb;
  font-weight: 500;
}

::-ms-input-placeholder {
  font-style: italic;
  color: #bbb;
  font-weight: 500;
}

.map-area {
  height: 617px;
  margin-top: 85px;
  background: #ddd;
}

textarea#content {
  border-radius: 10px;
}

.btn.focus,
.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.return_back {    
  transform: translateX(-50%);
  top: 15px;
  padding: 8px;
  position: absolute;
  left: 70px;
  line-height: 0px;
}

.return_back_mobile {    
  transform: translateX(-50%);
  top: 5px;
  position: absolute;
  left: 30px;
  border-radius: 5px;
  line-height: 0px;
}

.property {
  z-index: 1;
  padding-bottom: 60px;
}

.p-t-30 {padding-top: 30px;}
.p-b-30 {padding-bottom: 30px;}
.p-l-55 {padding-left: 55px;}
.p-r-55 {padding-right: 55px;}
.m-t-15 {margin-top: 15px;}
.m-b-15 {margin-bottom: 15px;}
.m-r-15 {margin-right: 15px;}
.m-l-15 {margin-left: 15px;}
.m-t-25 {margin-top: 25px;}
.m-b-25 {margin-bottom: 25px;}
.m-r-25 {margin-right: 25px;}
.m-l-25 {margin-left: 25px;}
.text-white {color: white;}


.section_subtitle {
  margin-top: 0;
  font-size: 14px;
  font-weight: 400;
  color: #92a4b8;
}

.header {
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
}

.header_content {
  width: 100%;
  height: 65px;
}

.header.scrolled .header_content {
  height: 65px;
}

.logo {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main_nav ul li:not(:last-of-type) {
  margin-right: 5px;
}

.menuLink {
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  color: #222;
}

.menu_link > a {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  color: #222;
}

.avatar-60 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.menu {
  z-index: 10000000;
  position: fixed;
  display: none;
  top: -100vh;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 15px 15px 40px #D3D3D3;
}

.menu.active {
  top: 0;
  display: initial;
}

.menu_content {
  width: 100%;
  height: 100%;
  margin-top: 50px;
}

.fab:hover {
  color: #e90062;
}

.footer-section {
  padding-top: 30px;
}

.row > .column {
  padding: 0 8px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  margin: 3px;
  float: left;
}

.cursor {
  cursor: pointer;
}

select {
  -webkit-appearance:none;
}


a {
  color: #222;
}

a:hover {
  color: #222;
}

.form-group {
  margin: 15px 0px;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

.select2-container--default .select2-selection--single {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #b8c4d3;
  font-size: 15px;
  padding: 0 16px;
  color: #4c4457;
  font-weight: 500;
  width: 100%!important;
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
}

.form-control {
  font-size: 14px;
  border-radius: 8px;
  padding-left: 15px;
  color: #222;
  border: 1px solid #e3e3e3;
  font-weight: 500;
  display: block;
  width: 100%;
  height: 40px;
  line-height: 1.8;
  vertical-align: middle;
  background: #ffffff;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  -webkit-appearance:none;
}

.form-control:focus, .form-control:active {
  border: 1px solid #ff2f92;
  box-shadow: none;
  color: #222;
}

.form-ctrl .select2-container--default {
  width: 100% !important;
}

.form-ctrl {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.shop-view>a {
  display: block;
  width: 43px;
  height: 43px;
  padding: 13px;
  float: left;
}

.shop-view>a.active i {
  color: #e90062 !important;
}

.shop-view>a.grid-view span::before {
  left: 6px;
}
.shop-view>a.grid-view span::after {
  left: 12px;
}

.shop-view>a.grid-view span::before, .shop-view>a.grid-view span::after {
  top: 0;
  width: 3px;
  height: 3px;
  content: '';
}
.shop-view>a span::before, .shop-view>a span::after {
  display: block;
  position: absolute;
  background-color: #222;
}
.shop-view>a.active span, .shop-view>a.active span::before, .shop-view>a.active span::after {
}
.shop-view>a.list-view span::before {
  top: 1px;
  left: 6px;
  width: 9px;
  height: 1px;
  content: '';
}

.text-right .btn, .text-right .btn-group {
  margin-right: 0;
  margin-left: 12px;
}

.ui-menu-item-wrapper {
  font-size: 15px;
  margin-left: 2px;
}
.ask_show_orderBy {
  margin-right: 10px;
}

.blog-entry-ad-horizontal {
  height: initial !important;
}

.btn {
  font-size: 13px;
  font-weight: bold;
  padding: 6px 28px;
}

.img_principal:hover {
  transform: scale(1.1);
  transition: all .5s;
}

svg.loader {
  transform-origin: center;
  animation: rotate 2s linear infinite;
  display: initial !important;
}

svg.loader circle {
  fill: none;
  stroke: #fc2f70;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}


#hire-vendor-mouseleave .sub-menu {
  width: 700px;
}
.sub-menu.sub-menu-top {
  z-index: 999;
}
.padding-zero {
  padding: 0px;
}
.sub-menu {
  left: calc(50% - 350px);
  position: absolute;
  top: 67px;
}

.menu-hover-left {
  line-height: 36px;
  text-align: left;
}

.menu-hover-left>div {
  padding: 0px;
}

.no-padding {
  padding: 0;
}

.sub-menu-data {
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 16px;
  background: #fff;
  overflow: hidden;
  font-size: 15px;
  line-height: 28px;
  padding: 12px 0px 15px 30px;
  color: #222;
  border-radius: 12px;
}

.menu-hover-left ul {
  margin: 0;
  padding: 0;
}

#hire-vendor-mouseleave .sub-menu ul li {
  padding: 0px 10px;
  text-transform: uppercase;
  font-size: 12px;
  list-style-type: none;
  margin: 0;
  width: 100%;
}
.sub-menu-top ul li {
  list-style-type: none;
  margin-bottom: 1px;
}

#hire-vendor-mouseleave .sub-menu ul li a {
  color: #222;
  padding: 4px 0;
  font-size: 16px;
  width: 100%;
  text-align: left;
  font-weight: 500;
  text-transform: initial;
}

.sub-menu-top ul li a {
  color: #222333;
  line-height: 41px;
  display: block;
  border: 1px solid #fff;
}
.menu-hover-left li a {
  color: #222;
}

.sub-menu-top ul li i {
  margin-right: 5px;
  font-size: 16px;
  position: relative;
}

#header-nav-vendor-v-bis {
  right: 0px;
}

#hire-vendor-mouseleave .sub-menu ul li a:hover {
  background: #fff;
  border-radius: 0px;
  text-decoration: none;
  color: #e90062;
  border-radius: 4px;
}

.hero-banner {
  height: 350px;
  text-align: center;
  color: white;
  background: var(--img-back);
  background-position: center;
  background-size: cover;
}

.ask-right {
 text-align: left; 
 flex: 1 1 70%;
}

.banner-overlay {
  height: 100%;
  position: relative;
}

.banner-container {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.c-container {
  max-width: 1140px;
  margin: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.normal-32, .bold-32 {
  text-shadow: 0 0 10px #00000080;
  font-size: 2.5em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  margin: 0;
}

.bold, .bold-16 {
  font-weight: bold;
}

.padb-40 {
  padding-bottom: 40px;
}

.brdr-t {
  border-top: 1px solid #ebebeb;
}

.pad-40 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.c-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
}

.sqr-col {
  width: 50%;
  display: flex;
  padding: 0px 16px 40px;
  box-sizing: border-box;
}

.sqr-col i {
  color: #e90062;
  font-size: 2em;
  margin-right: 12px;
}

.normal-16, .bold-16, .light-16 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: normal;
}

.brdr-t {
  border-top: 1px solid #ebebeb;
}

.t-column {
  -ms-flex: 25%;
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.t-column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  border-radius: 4px;
}

.filter-nav {
  padding-bottom: 40px;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.filter-nav>a {
  margin-right: 8px;
  line-height: 30px;
  padding: 0 16px;
  color: var(--brand) !important;
  border: 1px solid #ebebeb;
  border-radius: 100px;
  font-size: 0.875em;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.c-grid {
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: 50% 50%;
  grid-row-gap: 32px;
}

.o-cards {
  padding: 16px 16px 0;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid white;
  text-decoration: none;
  border: 1px solid #ebebeb;
}

.medium-18 {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #222;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;
}

.pink-style-1 {
  color: #fff;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
}

.eventsia-btn {
  font-weight: bold;
  line-height: 3em;
  height: 3em;
  text-align: center;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  border-radius: 30px !important;
  cursor: pointer;
  border: none;
  padding-left: 1em;
  padding-right: 1em;
}

.eventsia-btn:hover {
  color: white !important;
}

.light-16 {
  color: #70757A;
}

.t-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 4px;
}

.dark-text {
  color: #222;
}

.white-text {
  color: white;
}

.a-container, .guest-container {
  max-width: 1250px;
  margin: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.about-us, .offer, .different, .values, .join {
  text-align: center;
}

.values {
  background-color: #f2eeef;
}

.font-60 {
  font-size: 60px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #353b50;
}

.rec-box {
  max-width: 574px;
  margin: auto;
  text-align: center;
}

.bold, .bold-16 {
  font-weight: 700;
}

.padt-40 {
  padding-top: 40px;
}

.brdr-b {
  border-bottom: 1px solid #ebebeb;
}

.padb-16 {
  padding-bottom: 16px;
}

.padt-16 {
  padding-top: 16px;
}

.pad-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.sqr-col-33 {
  width: 33%;
  display: flex;
  padding: 0px 16px 40px;
  box-sizing: border-box;
}

.bold, .bold-16 {
  font-weight: 700;
}

.different .rec-flex p {
  text-align: left;
}

.rec-flex p {
  align-self: center;
}

.different .rec-flex {
  justify-content: left;
  max-width: 574px;
  margin: auto;
  padding-bottom: 16px;
}
.rec-flex {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
}

.different .rec-flex i {
  align-self: flex-start;
}
.rec-flex i {
  color: #e90062;
  font-size: 1.25em;
  margin-right: 12px;
  align-self: center;
}

.bold-24 {
  font-size: 24px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #222;
}

.padb-32 {
  padding-bottom: 32px;
}

.padt-32 {
  padding-top: 32px;
}

.pad-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.leader img {
  width: 200px;
}

.leader {
  text-align: center;
  padding-bottom: 40px;
  width: 33.33%;
  padding-left: 16px;
  padding-right: 16px;
  display: inline-block;
}

.lead-flex {
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  flex-wrap: wrap;
}

.leader .name {
  padding-top: 16px;
  padding-bottom: 8px;
}

.medium-18 {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #222;
}

.padlr-32 {
  padding-right: 32px;
  padding-left: 32px;
}

.wave-effect {
  animation-duration: 5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

h6 {
  font-size: 18px;
}

.toast__container {
  position: absolute;
  z-index: 1000000;
  top: 9%;
  right: 2px;
  border-top: 1px solid rgba(0,0,0,0.1);
  border-bottom: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.3);
}

.toast__cell{
  display:inline-block;
}

.add-margin{
  margin-top:20px;
}

.toast {
  text-align:left;
  background-color:#fff;
  border-radius:4px;
  padding: 15px 30px 15px 0px;
  top: 0px;
  position:relative;
  box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.2);
}


.toast:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius:4px;
  border-bottom-left-radius: 4px;
}

.toast__icon{
  position:absolute;
  top:50%;
  left:22px;
  transform:translateY(-50%);
  width:25px;
  height:25px;
  padding: 7px;
  border-radius:50%;
  display:inline-block;
}

.toast__type {
  color: #222;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1px;
}

.toast__message {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  color: #878787;
}

.toast__content{
  padding-left:65px;
  padding-right:20px;
}

.toast--success .toast__icon{
  background-color:#2BDE3F;
}

.toast--success:before{
  background-color:#2BDE3F;
}

.toast--error .toast__icon{
  background-color:#e74c3c;
}

.toast--error:before{
  background-color:#e74c3c;
}

.toast--warning .toast__icon{
  background-color:#FFC007;
}

.toast--warning:before{
  background-color:#FFC007;
}


@-webkit-keyframes ripple {
  0% {
    -webkit-transform:scale(1);
  }
  75% { 
    -webkit-transform:scale(1.75);
    opacity:1;
  }
  100% {
    -webkit-transform:scale(2);
    opacity:0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform:scale(1);
  }
  75% { 
    -webkit-transform:scale(1.75);
    opacity:1;
  }
  100% {
    -webkit-transform:scale(2);
    opacity:0;
  }
}


/* barre de recherche */
.css-1hnvqco {
  width: 100%;
  max-height: 48px;
  height: 48px;
  min-height: 48px;
  display: flex;
  color: rgb(13, 13, 13);
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  border-bottom-left-radius: 0px !important;
  flex: 1 1 0%;
}

.css-1q7eo6l {
  flex: 1;
  height: 100%;
  position: relative;
  -webkit-flex: 1;
}

.css-1un7frv {
  position: relative;
  height: 100%;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(222, 222, 222);
  border-image: initial;
  flex: 1 1 0%;
}

.css-7p4gzf {
  z-index: 4;
  position: absolute;
  left: 14px;
  top: 12px;
}

.css-1un7frv > input {
  height: 100%;
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.38;
  -webkit-appearance: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: inherit;
}
.css-171qeg2 {
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  padding-left: 48px;
}

.css-q8hq2r {
  position: absolute;
  top: 42px;
  left: 20px;
  z-index: 20;
  width: 100%;
  margin-top: 0px !important;
  max-height: 250px !important;
  overflow-y: overlay !important;
  border-color: rgb(222, 222, 222) rgb(222, 222, 222);
  border-bottom: 1px solid rgb(222, 222, 222);
  border-top: unset !important;
  background: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.css-q8hq2r > div {
  border-left: 1px solid rgb(222, 222, 222);
  border-right: 1px solid rgb(222, 222, 222);
}

.css-q8hq2rd {
  position: absolute;
  top: 59px;
  left: 38px;
  z-index: 20;
  width: 90%;
  margin-top: 0px !important;
  max-height: 252px !important;
  overflow-y: overlay !important;
  border-color: rgb(222, 222, 222) rgb(222, 222, 222);
  border-bottom: 1px solid rgb(222, 222, 222);
  border-top: unset !important;
  background: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.css-q8hq2rd > div {
  border-left: 1px solid rgb(222, 222, 222);
  border-right: 1px solid rgb(222, 222, 222);
}
.css-651r7n {
  height: 50px;
  min-height: 50px;
  display: flex;
  background-color: rgb(255, 255, 255);
  font-size: 13px;
  font-weight: bold;
  color: rgb(13, 13, 13);
  border-bottom-color: rgb(222, 222, 222);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 14px;
}

.css-qvefew {
  height: 50px;
  min-height: 50px;
  display: flex;
  background-color: white;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: rgb(13, 13, 13);
  -webkit-box-align: center;
  align-items: center;
}

.css-3zphnv {
  z-index: 50;
  height: 100%;
  min-height: 50px;
  width: 100%;
  display: flex;
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  color: rgb(13, 13, 13);
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  padding: 5px 13px;
  overflow: hidden;
  border-top: unset;
  border-bottom: 1px solid rgb(247, 247, 247);
}

.css-ngly9c {
  margin-left: 5px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}

.css-csxgfq {
  flex: 1;
  height: 100%;
  position: relative;
  -webkit-flex: 1;
}

.css-1yycy1s {
  position: relative;
  height: 100%;
  display: flex;
  border-top: 1px solid rgb(222, 222, 222);
  border-bottom: 1px solid rgb(222, 222, 222);
  flex: 1 1 0%;
}

.css-1yycy1s > input {
  height: 100%;
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.38;
  -webkit-appearance: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: inherit;
}

@media (min-width: 992px) {
  .css-1ezpfrr {
    width: 68px;
    height: 100%;
  }
}

.css-1ezpfrr {
  width: 50px;
  height: 100%;
  display: flex;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  color: white;
  font-size: 28px;
  cursor: pointer;
  white-space: nowrap;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: unset;
  margin-right: -1px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  text-decoration: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

.css-iwdbrg {
  z-index: 5;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
}


.css-1upmfyp {
  z-index: 50;
  height: 100%;
  min-height: 42px;
  width: 100%;
  display: flex;
  font-size: 13px;
  background-color: rgb(247, 247, 247);
  color: rgb(13, 13, 13);
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  padding: 5px 13px;
  overflow: hidden;
  border-top: unset;
  border-bottom: 1px solid rgb(247, 247, 247);
}


.show-profile {
  margin: 0px; 
  padding: 10px 0px 15px; 
  display: flex;
}


.menu-sidebar {
  display: none;
  position: fixed;
  left: 0;
  top: 0px;
  width: 275px;
  bottom: 0;
  z-index: 1000;
  background: #fff;
  overflow-y: auto;
  box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.5);
}

.menu-sidebar .logo {
  background: #fff;
  height: 60px;
  padding: 0px 0px 0px 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #e5e5e5;
  position: relative;
  z-index: 3;
}

.menu-sidebar .navbar__list .navbar__sub-list {
  display: none;
  padding-left: 34px;
}

.menu-sidebar .navbar__list .navbar__sub-list li a {
  padding: 11.5px 0;
}

.page-container-hide {
  padding-left: 0px !important;
}

.navbar-sidebar {
  padding: 10px 0px;
  padding-bottom: 0;
}

.navbar-sidebar .navbar__list li a {
  display: block;
  color: #7a818c;
  font-size: 14px;
  padding: 15px;
  font-weight: 600;
}

.navbar-sidebar .navbar__list li a i {
  margin-right: 12px;
  font-size: 18px;
}

.navbar-sidebar .navbar__list li.active > a {    
  border-left: 3px solid #e90062;
  color: #e90062;
}

.navbar-sidebar .navbar__list li.active > a i {    
  color: #e90062 !important;
}

.has-sub {
  position: relative;
}

.header-mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header-mobile .header-mobile__bar {
  padding: 10px 0;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

.header-mobile .header-mobile-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-mobile .hamburger {
  width: 36px;
  height: 36px;
  padding: 0;
  line-height: 1;
  vertical-align: top;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-mobile .hamburger .hamburger-box {
  width: 20px;
  height: 15px;
}

.header-mobile .hamburger .hamburger-box .hamburger-inner {
  width: 20px;
  height: 2px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.header-mobile .hamburger .hamburger-box .hamburger-inner:before {
  width: 20px;
  height: 2px;
  top: 6px;
}

.header-mobile .hamburger .hamburger-box .hamburger-inner:after {
  top: 12px;
  width: 20px;
  height: 2px;
}

.header-mobile .navbar-mobile {
  display: none;
  position: absolute;
  width: 100%;
  top: 88px;
  z-index: 20;
}

.header-mobile .navbar-mobile .navbar-mobile__list {
  background: #fff;
}

.header-mobile .navbar-mobile .navbar-mobile__list > li > a {
  padding-left: 15px !important;
}

.header-mobile .navbar-mobile .navbar-mobile__list li a {
  color: #555;
  display: block;
  padding: 10px 15px;
  padding-right: 25px;
  padding-left: 0;
  border-bottom: 1px solid #e6e6e6;
  line-height: inherit;
}

.header-mobile .navbar-mobile .navbar-mobile__list li a:hover {
  color: #4272d7;
}

.header-mobile .navbar-mobile .navbar-mobile__list li a > i {
  margin-right: 19px;
}

.header-mobile .navbar-mobile .navbar-mobile__list li.has-dropdown > a:after {
  content: '\f105';
  font-family: 'Eventsia', sans-serif;
  float: right;
  font-size: 16px;
  line-height: 22px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header-mobile .navbar-mobile .navbar-mobile__list li.has-dropdown > a.active::after {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.header-mobile .navbar-mobile .navbar-mobile__dropdown {
  padding-left: 35px;
  display: none;
}

.navbar-mobile-sub__list {
  display: none;
}

.header-mobile .navbar-mobile .navbar-mobile-sub__list li a {
  padding-left: 15px;
}

.css-md758y {
  width: 100%;
  max-height: 48px;
  height: 48px;
  min-height: 48px;
  display: flex;
  color: rgb(13, 13, 13);
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex: 1 1 0%;
}

.home-title {
  text-align: left;
  font-size: 26px;
  font-weight: 700;
  color: #222;
}

.prestaCarousel .swiper-slide {
  width: 310px;
  display: inline-table;
  position: relative;
  height: 250px !important;
  overflow: visible;
  margin-right: 16px;
  border-radius: 8px;
}

.prestaCarousel .swiper-slide .swiper-img {
  height: 250px;
  width: 250px;
  background: #ecf0f1;
}

.trendCarousel .swiper-slide {
  width: 250px;
  display: inline-table;
  position: relative;
  height: 250px !important;
  overflow: hidden;
  margin-right: 16px;
  border-radius: 8px;
}

.trendCarousel .swiper-slide .swiper-img {
  height: 250px;
  width: 250px;
  background: #ecf0f1;
}

.swiper-slide img {
  width: 100%;
  object-fit: cover;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s infinite linear;
  box-sizing: border-box;
  border: 4px solid #e90062;
  border-radius: 50%;
  border-top-color: transparent;
}

.css-3zphnv:hover {
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  color: #fff;
}

.tooltip {
  font-family: 'Eventsia', sans-serif;
  font-weight: 500;
}

.tooltip-inner {
  font-family: 'Eventsia', sans-serif;
  font-weight: 500;
}

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
}

.filter-p2 ._1jxweeom {
  height: 62px;
  border-radius: 30px;
  margin: 0 auto;
  box-shadow: rgba(202, 202, 202, 0.15) 0px 0px 0px 6px;
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(222, 222, 222);
  border-image: initial;
}

.filter-p2 ._1jkbosm7 {
  -webkit-flex: 1 0 0%;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  min-width: 0px;
}


.filter-p2 ._4w946cw {
  -webkit-box-align: center;
  -ms-flex-align: center;
  position: relative;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 0 0%;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  margin: -1px;
  min-width: 0px;
}

.filter-p2 ._gor68n {
  position: relative;
  display: flex;
}

.filter-p2 ._fmav2so {
  font-weight: 800;
  letter-spacing: 0.04em;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 2px;
}

.filter-p2 ._1xq16jy {
  padding-left: 15px !important;
  display: block;
  border: 0px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  background: none;
  font-size: 15px;
  line-height: 18px;
  color: #222222;
  text-overflow: ellipsis;
  font-weight: 600;
}


.filter-p2 ._n3o7pji::after {
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 32px;
  bottom: 0px;
  content: "";
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 0;
}

.filter-p2 ._n3o7pji {
  cursor: text;
  display: block;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 32px;
  -webkit-flex: 1 0 0%;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  min-width: 0px;
  padding: 21px 20px 21px 30px;
  margin: 0px;
}

.filter-p2 ._krjbj {
  border: 0px;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.filter-p2 ._krjbj {
  border: 0px;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}


.filter-p2 ._43mycv {
  -ms-grid-row-align: center;
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  border-right: 1px solid #DDDDDD;
  -webkit-flex: 0 0 0px;
  -ms-flex: 0 0 0px;
  flex: 0 0 0px;
  height: 32px;
}


.filter-p2 ._1yulsurh {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: -1px;
  min-width: 0px;
  position: relative;
  -webkit-flex: 0.95 0 auto;
  -ms-flex: 0.95 0 auto;
  flex: 0.95 0 auto;
}

.filter-p2 ._1omae7o6 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0px;
  color: inherit;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0px;
  outline: none;
  overflow: visible;
  /*padding: 1px;*/
  padding: 14px 0px;
  text-align: left;
  text-decoration: none;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  -webkit-flex: 1 0 0%;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  width: 0px;
  z-index: 1;
}

.filter-p2 ._seuyf {
  display: flex;
  padding: 17px 20px 17px 20px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}

.filter-p2 ._1omae7o6::after {
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 32px;
  bottom: 0px;
  content: "";
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 0;
}
.filter-p2 ._wtz1co {
  font-size: 12px;
  line-height: 16px;
  font-weight: 800;
  letter-spacing: 0.04em;
  padding-bottom: 2px;
}
.filter-p2 ._uh2dzp {
  font-size: 14px;
  line-height: 18px;
  color: #717171;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.filter-p2 ._w64aej {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: -6px;
  padding-right: 9px;
  position: relative;
  z-index: 5;
}

.filter-p2 ._1mzhry13 {
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
}

.filter-p2 ._1mzhry13 {
  appearance: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  text-align: inherit;
  user-select: auto;
  height: 48px;
  max-width: 48px;
  position: relative;
  vertical-align: middle;
  z-index: 0;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  margin: 0px;
  outline: none;
  overflow: hidden;
  padding: 0px;
  text-decoration: none;
  border-radius: 24px;
  transition: max-width 0.2s cubic-bezier(0.35, 0, 0.65, 1) 0s;
}


.filter-p2 ._tcp689 {
  display: block;
  position: relative;
  pointer-events: none;
}

.filter-p2 ._163rr5i {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  -webkit-mask-image: -webkit-radial-gradient(center, white, black);
  overflow: hidden;
  border-radius: 8px;
}

.filter-p2 ._m9v25n {
  display: block;
  width: 100%;
  height: 100%;
  min-width: 200px;
  background-size: 200% 200%;
  opacity: 0;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  transition: opacity 1.25s ease 0s;
}

.filter-p2 ._1hb5o3s {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  padding: 16px;
  position: relative;
  z-index: 1;
}

.filter-p2 ._1eh6notg {
  font-weight: 600;
  opacity: 0;
  padding-left: 8px;
  padding-right: 4px;
  transition: opacity 0.1s cubic-bezier(0.35, 0, 0.65, 1) 0s;
}


.pulse-button {
  box-shadow: 0 0 0 0 rgba(233, 0, 98, 0.9);
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.pulse-button:hover {
  -webkit-animation: none;-moz-animation: none;-ms-animation: none;animation: none;
}

.flashit{
  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 1s infinite;
}

#map .gm-style .gm-style-iw-c { 
  position: absolute;
}

#map .gm-style .gm-style-iw-t { 
  position: absolute;
}

@-webkit-keyframes flash {
  0% { opacity: 1; } 
  50% { opacity: .1; } 
  100% { opacity: 1; }
}

@keyframes flash {
  0% { opacity: 1; } 
  50% { opacity: .1; } 
  100% { opacity: 1; }
}

._67e5n4c {
  cursor: pointer !important;
  display: inline-block !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  touch-action: manipulation !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 600 !important;
  border-radius: 30px !important;
  outline: none !important;
  padding: 12px 42px !important;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important;
  border: none !important;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%) !important;
  color: rgb(255, 255, 255) !important;
}


.title-wrapper-3KgEa div {
  font-size: 14px;
  line-height: 10px;
  color: #161823;
  margin-top: 25px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-container-3hjAo {
  position: relative;
}

.input-field-pc-FOzso {
  border-radius: 4px;
  position: relative;
  margin-top: 8px;
  background: rgba(22,24,35,.06);
}

.input-field-3x_mo {
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  margin-top: 12px;
  box-sizing: content-box;
}

.input-field-pc-FOzso input {
  padding-left: 12px;
  background: transparent;
  outline: none;
  border-radius: 4px;
}

.input-field-3x_mo input {
  height: 100%;
  width: 100%;
  padding: 0 20px;
  border: none;
  direction: ltr;
  color: #161823;
  font-size: 14px;
  border: 1px solid rgba(22,24,35,.06);
}

.link-2j8GS.grey-rBkrL {
  color: rgba(22,24,35,.75);
}

.link-2j8GS.small-1UkQD {
  font-size: 12px;
  line-height: 15px;
}
.link-2j8GS {
  margin: 0;
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  color: #161823;
  font-weight: 600;
  cursor: pointer;
}

.login-button-31D24.highlight-1TvcX {
  color: #fff;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  border: none;
}

.login-button-31D24 {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #161823;
  border: 1px solid rgba(22,24,35,.12);
  background: none;
  border-radius: 2px;
  font-size: 16px;
  line-height: 44px;
  font-weight: 600;
  position: relative;
  margin-top: 32px;
  padding: 0;
  cursor: pointer;
}

.pc-title-1JpDs {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  font-weight: bolder;
  color: #000;
}

.footer-bottom-wrapper-1a-rL {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.toggle-2SAdO.is-modal-1F8S3 {
  justify-content: center;
  align-items: center;
  padding: 0 24px;
}

.toggle-2SAdO {
  height: 64px;
  background: #fff;
  border-top: .5px solid rgba(22,24,35,.12);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #161823;
}

.link-2j8GS.big-2_yje {
  font-size: 14px;
  line-height: 18px;
}

.link-2j8GS {
  margin: 0;
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  color: #161823;
  font-weight: 600;
  cursor: pointer;
}

.social-container-NE2xk .channel-item-wrapper-2gBWB .channel-icon-wrapper-2eYxZ .channel-icon-33qGs {
  width: 24px;
  min-height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.social-container-NE2xk .channel-item-wrapper-2gBWB .channel-icon-wrapper-2eYxZ {
  width: 44px;
  height: 44px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
}

.social-container-NE2xk .channel-item-wrapper-2gBWB {
  width: 100%;
  min-height: 50px;
  border: 1px solid rgba(22,24,35,.12);
  box-sizing: border-box;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 12px 0 0;
  cursor: pointer;
  background: white;
}

.social-container-NE2xk .channel-item-wrapper-2gBWB .channel-name-2qzLW {
  word-break: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  font-size: 15px;
  color: #161823;
  font-weight: 600;
}

.social-container-NE2xk {
  overflow-y: auto;
  width: 100%;
  padding: 0px 20px;
}

._7nxhq3b {
  background: rgb(255, 255, 255) !important;
  border-radius: 12px !important;
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 16px !important;
  color: rgb(34, 34, 34) !important;
  margin-top: 34px !important;
  padding: 8px !important;
  position: absolute !important;
  top: 50% !important;
  right: 0px !important;
  max-height: calc(100vh - 100px) !important;
  overflow-y: auto !important;
  z-index: 20000000 !important;
  min-width: 240px !important;
}

._14tt8lmp {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: transparent !important;
  border: 0px !important;
  color: #222222 !important;
  cursor: pointer !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  margin: 0px !important;
  outline: none !important;
  overflow: visible !important;
  padding: 12px 16px !important;
  text-align: inherit !important;
  text-decoration: none !important;
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
  white-space: nowrap !important;
  width: 100% !important;
  -webkit-align-items: center !important;
  align-items: center !important;
}

._ojs7nk {
  -webkit-flex: 1 0 auto !important;
  -ms-flex: 1 0 auto !important;
  flex: 1 0 auto !important;
}


._1qvp8oxp {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: transparent !important;
  border: 0px !important;
  color: #222222 !important;
  cursor: pointer !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  margin: 0px !important;
  outline: none !important;
  overflow: visible !important;
  padding: 12px 16px !important;
  text-align: inherit !important;
  text-decoration: none !important;
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
  white-space: nowrap !important;
  width: 100% !important;
  -webkit-align-items: center !important;
  align-items: center !important;
}

._apke5g {
  background: #DDDDDD !important;
  margin: 8px 0 !important;
  height: 1px !important;
}


._tbbnrnx i {
  font-size: 22px; 
  color: #B0B0B0; 
  margin-bottom: 4px;
}

._jgit7bg i {
  font-size: 22px; 
  margin-bottom: 4px;
}

._1m294zs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  border-top: 1px solid #EBEBEB;
  position: fixed;
  bottom: -60px;
  height: 120px;
  left: 0px;
  right: 0px;
  padding-bottom: 60px;
  background: #ffffff;
  -webkit-transition: -webkit-transform .2s cubic-bezier(0.455, 0.030, 0.515, 0.955),transform .2s cubic-bezier(0.455, 0.030, 0.515, 0.955), visibility .2s;
  -moz-transition: transform .2s cubic-bezier(0.455, 0.030, 0.515, 0.955), visibility .2s;
  transition: -ms-transform .2s cubic-bezier(0.455, 0.030, 0.515, 0.955),-webkit-transform .2s cubic-bezier(0.455, 0.030, 0.515, 0.955),transform .2s cubic-bezier(0.455, 0.030, 0.515, 0.955), visibility .2s;
  z-index: 1000;
}

._1m294zs {
  margin-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

._tbbnrnx {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 2px;
  color: #B0B0B0;
  position: relative;
}


._h0klba {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 auto;
}

._45o9ez {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  border: 0px;
  max-width: 20%;
  min-width: 0px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding: 0 2px;
}

._1e1aumhl {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  border: 0px;
  max-width: 20%;
  min-width: 0px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding: 0 2px;
  color: #FF385C;
}

._jro6t0 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}

._no3jwgi {
  overflow: clip;
}

._no3jwgi {
  font-size: 10px;
  line-height: 12px;
  max-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  overflow-wrap: break-word;
  color: #222222;
}

._jgit7bg {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 2px;
  position: relative;
  color: #e90062;
}


._1bntpbx {
  overflow: clip;
}
._1bntpbx {
  font-size: 10px;
  line-height: 12px;
  max-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  overflow-wrap: break-word;
  color: #717171;
}

._no3jwgi {
  font-size: 10px;
  line-height: 12px;
  max-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  overflow-wrap: break-word;
  color: #222222;
}

._469le2 {
  height:  100%;
  width:  100%;
}

.hqXCUZ {
  color: #222;
}

.eUnIIY {
  font-family: 'Eventsia', sans-serif;
  font-size: 1.125rem;
  font-weight: bold;
  padding: 0px 1rem;
  text-align: center;
  border: none;
  border-radius: 0.25rem;
  color: white;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  position: relative;
  cursor: pointer;
  height: 3rem;
  min-width: 20rem;
}

.eoGDzK {
  text-decoration: none;
  color: white;
  text-align: center;
  padding: 10px 0px;
  font-size: 22px;
  font-family: 'Eventsia', sans-serif;
  font-weight: bold;
}

.tt-headline.loading-bar .tt-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}
.tt-headline.loading-bar span {
  display: inline-block;
  padding: .2em 0;
}
.tt-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: center;
}
.tt-headline.loading-bar b.is-visible {
  opacity: 1;
  top: 0;
}
.tt-headline.loading-bar b {
  top: .2em;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.tt-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  right: 0;
  top: 0;
  font-weight: 900;
  text-transform: uppercase;
}

.iXTbep::after {
  content: "OU";
  color: #000;
  position: relative;
  top: -13px;
  background: rgb(255, 255, 255);
  padding: 0px 12px;
}

.iXTbep {
  border-right-width: initial;
  border-bottom-width: initial;
  border-left-width: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  color: rgb(215, 215, 215);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  opacity: 1 !important;
  border-style: solid none none;
  border-image: initial;
  border-top: 1px solid rgb(215, 215, 215);
  overflow: visible;
  width: 100%;
}

.other-logins {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.social-icons-fb {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 50px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(215, 215, 215);
  border-image: initial;
  border-radius: 2px;
}

.social-icons-fb p {
  font-size: 15px;
  margin: 0px;
  margin-left: 20px;
}

.social-icons-google {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 50px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(215, 215, 215);
  border-image: initial;
  border-radius: 2px;
}

.social-icons-google p {
  font-size: 15px;
  margin: 0px;
  margin-left: 20px;
}


.fZQoNm {
  text-decoration: none;
  color: white;
  text-align: center;
  padding-top: 25px;
  font-size: 22px;
  font-family: 'Eventsia', sans-serif;
  font-weight: bold;
}

.kdOAmQ {
  display: flex;
  flex-direction: column;
  padding: 0.625rem;
}

.gQRraV {
  color: #222;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.15;
}

.KcCIX {
  font-family: 'Eventsia';
  font-weight: 500;
  color: #222;
  padding: 0.625rem 0px;
  height: 2.5rem;
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  border-image: initial;
  background-color: transparent;
  cursor: initial;
  border-bottom-style: solid;
  border-bottom-color: rgb(207, 207, 207);
  border-radius: 0px;
  margin-top: 0px;
  font-size: 15px;
}

.KcCIX::-webkit-input-placeholder,
.KcCIX::-webkit-input-placeholder {
  color: rgb(207, 207, 207);
}

.KcCIX::placeholder,
.KcCIX::-webkit-input-placeholder {
  color: rgb(207, 207, 207);
}

.equHBw {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  align-self: stretch;
}

.hIMiPJ {
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
}

.gvVuFN {
  font-family: 'Eventsia', sans-serif;
  font-weight: bold;
  text-align: center;
  border: none;
  color: white;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  position: relative;
  cursor: pointer;
  width: initial;
  align-self: center;
  font-size: 1rem;
  padding: 0px 2rem;
  margin-top: 20px;
  border-radius: 0.25rem;
}

.kYKazV {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
}

.ktGuED {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

.ivOodO {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.lowIgj {
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
}

.kyOvTV {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex: 1 1 0%;
}

.lewZOv {
  color: #999;
  padding: 0px;
  font-size: 13px;
}

.cSPfCG {
  color: #e90062;
  cursor: pointer;
  text-decoration: underline;
}

.cSPfCG:hover {
  color: #e90062;
}

.nbRcd {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 56px);
}

.cuOtHq {
  display: flex;
  flex-direction: column;
}

.errMsg-1ZYT3 {
  font-size: 12px;
  line-height: 15px;
  color: #ff4c3a;
  margin-top: 6px;
  display: flex;
  align-items: center;
}

.input-field-pc-FOzso.error-bPXv8 input {
  color: #ff4c3a;
}

.input-field-pc-FOzso.error-bPXv8 {
  border: 1.5px solid #ff4c3a;
  position: relative;
}

.svg-selected path {
  fill: #e90062 !important;
}

._nyj4ph4 {
  background-color: rgb(255, 56, 92) !important;
  box-shadow: rgb(255 255 255) 0px 0px 0px 1.5px !important;
  position: absolute !important;
  z-index: 1 !important;
  border-radius: 9px !important;
  color: rgb(255, 255, 255) !important;
  font-size: 10px !important;
  font-weight: 800 !important;
  height: 16px !important;
  left: 60% !important;
  line-height: 16px !important;
  margin-left: -14px !important;
  min-width: 16px !important;
  padding: 0px 5px !important;
  text-align: center !important;
  top: 8px !important;
}

._19sffb {
  background-color: rgb(255, 56, 92) !important;
  border-radius: 50% !important;
  display: inline-block !important;
  height: 6px !important;
  left: 1px !important;
  margin-right: -6px !important;
  position: relative !important;
  right: 6px !important;
  top: -2px !important;
  vertical-align: top !important;
  width: 6px !important;
}


.adminFiltersBox__select--blood {
  margin: 0;
}
.adminFiltersBox__select {
  display: inline-block;
  vertical-align: top;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background: #fff;
  font-weight: 400;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  border-left: 1px solid #d9d9d9;
}

.adminFiltersBox__dropdown {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  min-width: 100%;
  left: 0;
  top: 35px;
  z-index: 999;
  background: #fff;
  position: absolute;
  text-align: left;
}

.adminFiltersBox__dropdown li {
  border-bottom: 1px solid #d9d9d9;
  list-style: none;
}

.adminFiltersBox__dropdown li a {
  padding: 10px 15px;
  white-space: nowrap;
  display: block;
}


@media (min-width: 980px) {
  .eoGDzK {
    width: auto;
    color: #222;
  }
  .fZQoNm {
    width: auto;
    color: #222;
  }
  .equHBw {
    padding: 0px;
    align-self: initial;
    margin-top: initial;
    -webkit-box-pack: center;
    justify-content: center;
    flex-basis: 20%;
    align-items: flex-end;
    background-color: transparent;
  }
  .KcCIX {
    font-size: 1rem;
  }
  .kyOvTV {
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 37rem;
    padding-bottom: 0px;
  }
  .ivOodO {
    flex-direction: row;
    flex: initial;
  }
  .nbRcd {
    flex-direction: row;
    overflow-y: initial;
  }
  .cuOtHq {
    flex: 3 1 0%;
    margin: auto 0;
  }
}


@media (max-width: 979px) {

  .iXTbep {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .social-icons-google, .social-icons-fb {
    height: 45px;
  }

  .nbRcd {
   height: initial;
 }

 .cSAyCg {
  display: none;
}

.gQRraV {
  line-height: 1.15;
}

.fZQoNm {
  color: #222;
  font-size: 1.25rem;
}

.eoGDzK {
  color: #222;
  font-size: 1.1rem;
}

.input_last {
  padding-top: 1.5rem;
}
}


@media only screen and (min-width: 1440px) {
  .header {
    padding-right: 80px;
    padding-left: 80px;
  }

  .footer-warp {
    padding-right: 80px;
    padding-left: 80px;
  }
}


@media only screen and (max-width: 1280px) {
  .hide-ipad {
    display: none;
  }
  .phone > div {
    font-size: 14px;
  }
  .main-menu {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .main_nav {
    display: none;
  }
  .header .phone {
    display: none !important;
  }
  header, footer {
    display:  none;
  }
  h6 {
    font-size: 16px;
  }

  .hide-mobile {
    display: none;
  }

  .show-button-application {
    display: flex !important;
  }


  .ask-right {
   text-align: left; 
   flex: 1 1 60%;
 }

 .mt-5 {
  margin-top: 25px !important;
}
.tab-content {
  padding: 15px 0px;
}
}

@media only screen and (max-width: 767px) {
  .filter-p2 ._1jkbosm7 {
    box-shadow: rgba(202, 202, 202, 0.15) 0px 0px 0px 6px;
    background: rgb(255, 255, 255);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(222, 222, 222);
    border-image: initial;
    border-radius: 30px;
  }
  .filter-p2 ._1jxweeom {
    border: none;
    height: initial;
    box-shadow: none;
  }
  .filter-p2 ._1yulsurh {
    box-shadow: rgba(202, 202, 202, 0.15) 0px 0px 0px 6px;
    background: rgb(255, 255, 255);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(222, 222, 222);
    border-image: initial;
    border-radius: 30px;
    margin-top: 20px;
  }
  .filter-p2 ._1omae7o6 {
    padding: 15px 20px 15px 30px;
    z-index: 19;
  }
  .filter-p2 ._43mycv {
    display: none;
  }
  .filter-p2 ._seuyf {
    padding: 0px;
  }
  .filter-p2 ._w64aej {
    z-index: 0;
  }
  .css-q8hq2rd {
    top: 45px;
    left: 0px;
    z-index: 20;
    width: 110%;
  }
  .css-q8hq2r {
    position: absolute;
    top: 45px;
    left: 0px;
    z-index: 20;
    width: 100%;
  }
  #orderby {
    margin-top: 3px;
  }
  .footer-widget {
    margin-top: 25px;
  }
  .img_principal:hover {
    transform: none !important;
  }
  .footer-section {
    padding-top: 0px;
  }
  .ask_show_orderBy {
    display: none;
  }
  .announcement_block_vertical {
    display: none;
  }
  .blog-entry .text .heading {
    margin-top: -12px;
  }
  .js-btn-favoris {
    display: initial !important;
  }
  .blog-entry .text {
    padding: 20px 10px 10px 10px;
  }
  .fc .fc-toolbar {
    padding-top: 0px;
  }
  .fc .fc-state-default {
    font-size: 12px;
  }
  .css-1hnvqco {
    display: inline-block;
  }
  .css-1un7frv {
    border-radius: 4px;
  }
  .css-csxgfq {
    margin: 10px 0px;
  }
  .css-1ezpfrr {
    width: 100%;
    border-radius: 4px;
    font-size: 15px;
  }
  .css-1yycy1s {
    border: 1px solid rgb(222, 222, 222);
    border-radius: 4px;
  }
  .hide-search {
    display: none;
  }
  .show-search {
    display: initial !important;
  }
}

@media only screen and (max-width: 640px) {
  .why {
    padding-top: 40px;
    text-align: center;
  }
  .t-column {
    -ms-flex: 25%;
    flex: 50%;
    max-width: 50%;
  }
  .sqr-col {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .header_right {
    right: 5px;
  }
  .footer-widget.list-widget ul {
    display: inline-block;
    padding: 0px;
    list-style: none;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 22px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 539px) {
  .card_img {
    width: 120px;
    height: 120px;
  }
  .hamburger i {
    color: #222;
  }
  .img-icon {
    width: 50px;
    height: 50px;
  }
  .card_text {
    font-size: 13px;
  }
  .cookie-banner-text {
    font-size: 11px !important;
  }
  .block-first {
    height: initial !important;
    padding: 5px !important;
  }
  .home-title {
    font-size: 22px;
  }
}


@media only screen and (max-width: 480px) {
  .toast__container {
    left: 0%;
  }
}

@media (min-width: 950px) {
  .filter-p2 ._1jkbosm7 {
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .filter-p2 ._1eh6notg {
    opacity: 1;
    transition: none 0s ease 0s;
  }
  .filter-p2 ._1mzhry13 {
    max-width: 200px;
    transition: none 0s ease 0s;
  }
}


@media only screen and (min-width: 992px) {
  .container {
    min-height: 90vh;
  }

  .number-result {
    margin-top: 20px;
  }

  .show-mobile {
    display: none;
  }

  ._1m294zs {
    display: none;
  }

  #return-to-top {
    position: fixed;
    bottom: 90px;
    right: 27px;
    width: 54px;
    background-color: rgba(0,0,0,.3);
    height: 54px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    z-index: 10;
  }

  #return-to-top i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 18px;
    top: 17px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .filter-p2 ._1jxweeom {
    display: flex;
    position: relative;
    width: 100%;
  }
  .filter-p2 ._1c7nvmy {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    height: 100%;
    min-width: 0px;
    pointer-events: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.list-widget ul {
    margin-right: 20px;
  }
}

